import { cssBundleHref } from "@remix-run/css-bundle";
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction } from
"@remix-run/node";
import { json } from "@remix-run/node";
import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError } from
"@remix-run/react";
import { AuthenticityTokenProvider } from "remix-utils/csrf/react";
import stylesheet from "~/tailwind.css";
import { ExternalScripts } from "remix-utils/external-scripts";
import { getEnv } from "~/utils/env.server";
import { csrf } from "~/utils/csrf.server";
import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { commitSession, getSession } from "./utils/model-utils/session.server";
import { combineHeaders } from "./utils/misc.server";
import {
  AlertCircle,
  CheckCircle2Icon,
  Loader2Icon,
  MoveLeftIcon } from
"lucide-react";
import { Button } from "./components/ui/Button";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { honeypot } from "./utils/honeypot.server";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import image_404 from "~/assets/images/rangersocks_404_optimized.jpg";

export const links: LinksFunction = () => [
{ rel: "stylesheet", href: stylesheet },
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : [])];


export async function loader({ request }: LoaderFunctionArgs) {
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken(request);

  const session = await getSession(request);
  let toastData = session.get("toastMessage");
  toastData = toastData ? toastData : null;

  return json(
    {
      toastData,
      ENV: getEnv(),
      csrfToken,
      honeypotInputProps: honeypot.getInputProps()
    },
    {
      headers: combineHeaders(
        csrfCookieHeader ? { "set-cookie": csrfCookieHeader } : {},
        {
          "Set-Cookie": await commitSession(session)
        }
      )
    }
  );
}

export function App() {
  const loaderData = useLoaderData<typeof loader>();
  let toastData = loaderData.toastData;

  interface StateProperties {
    String: string;
  }
  const [toastedIDs, setToastedIDs] = useState<StateProperties[]>([]);

  // Toaster
  useEffect(() => {
    if (!toastData || !toastData.hasOwnProperty("message")) {
      return;
    }

    if (toastedIDs.includes(toastData?.stamp)) {
      return; // avoid double toasting
    }
    toastedIDs.push(toastData.stamp);
    setToastedIDs(toastedIDs);

    let toastDuration = toastData.message.length * 60; // ms duration per character
    const minimumToastDuration = 1500; // ms of min toast duration
    if (toastDuration < minimumToastDuration) {
      toastDuration = minimumToastDuration;
    }

    if (toastData?.status === "success") {
      toast.success(toastData.message, {
        duration: toastDuration,
        id: toastData.stamp
      });
    } else if (toastData?.status === "error") {
      toast.error(
        <span className="flex flex-row items-center gap-4">
          {toastData.message}
          <Button
            onClick={() => toast.dismiss(toastData.stamp)}
            type="button"
            variant="outline">

            Dismiss
          </Button>
        </span>,
        {
          id: toastData.stamp,
          duration: toastDuration + 10000 // Add an extra 10 seconds
        }
      );
    } else {
      toast(toastData.message, {
        duration: toastDuration,
        id: toastData.stamp
      });
    }
  }, [toastData, toastedIDs]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />

        <meta name="format-detection" content="telephone=no" />
        <Meta />
        <Links />
      </head>
      <body className="min-h-screen text-primary [overflow-y:initial!important] ">
        <section
          vaul-drawer-wrapper="true"
          className="flex min-h-screen flex-col bg-background xl:overflow-x-visible">

          <Toaster
            toastOptions={{
              success: {
                icon:
                <CheckCircle2Icon
                  size="20"
                  className="shrink-0 text-shamrock-600" />


              },
              error: {
                icon: <AlertCircle size="20" className="shrink-0" />
              },
              loading: {
                icon:
                <Loader2Icon size="20" className="shrink-0 animate-spin" />

              }
            }} />

          <Outlet />
        </section>
        <ScrollRestoration />
        <Scripts />
        <ExternalScripts />
        <LiveReload />
      </body>
    </html>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  const { honeypotInputProps } = data;
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
      <HoneypotProvider {...honeypotInputProps}>
        <App />
      </HoneypotProvider>
    </AuthenticityTokenProvider>);

}

export default withSentry(AppWithProviders);

export const meta: MetaFunction = () => {
  return [
  { title: "Ranger Budget" },
  {
    property: "og:title",
    content: "Ranger Budget"
  }];

};

export function ErrorBoundary() {
  const error = useRouteError();

  // Sentry
  captureRemixErrorBoundaryError(error);

  // if true, give user friendly errors. if false, give full dev errors.
  const isProduction = process.env.NODE_ENV === "production";

  let errorStatus;
  let errorMessage;

  // Determines error status:
  // - Route response error: uses error.status
  // - Internal server error (if error instanceof Error): status 500
  // - Otherwise: status is unknown
  if (isRouteErrorResponse(error)) {
    errorStatus = `${error.status}`;
    // error.data is our dev friendly, high level 'what went wrong'
    errorMessage = !isProduction ? `${error.data}` : "Oops!";
  } else {
    errorStatus = error instanceof Error ? "500" : "Unknown Error";
    // error is our dev friendly, high level 'what went wrong'
    errorMessage = !isProduction ? `${error}` : "Oops!";
  }

  // user friendly errors
  const niceErrorMessage =
  errorStatus === "404" ?
  "Sorry, we couldn’t find the page you’re looking for." :
  "Sorry, something went wrong";

  return (
    <html>
      <head>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <main
          className="relative min-h-screen"
          style={{
            backgroundImage: `url(${image_404})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundAttachment: "fixed"
          }}>

          <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
            <p className="text-base font-semibold leading-8 text-white">
              {errorStatus}
            </p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
              {errorMessage}
            </h1>
            {!isProduction && error instanceof Error &&
            <div className="mt-4 w-fit bg-gray-100 p-8 text-gray-900">
                <pre className="text-left text-sm">{error.stack}</pre>
              </div>}

            <p className="mt-4 text-base text-white/70 sm:mt-6">
              {niceErrorMessage}
            </p>
            <div className="mt-10 flex justify-center">
              <Button variant="ghost" asChild>
                <Link
                  className="flex items-center gap-2 text-sm font-semibold leading-7 text-white"
                  to="/transactions">

                  <span>
                    <MoveLeftIcon />
                  </span>
                  Back to Budgeting
                </Link>
              </Button>
            </div>
          </div>
        </main>
        <Scripts />
      </body>
    </html>);

}